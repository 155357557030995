import { AlertColor, Box, Button, Stack, Typography } from "@mui/material";
import "./UploadPage.css";
import { ChangeEvent, useEffect, useRef } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TaskIcon from "@mui/icons-material/Task";
import SimCardAlertIcon from "@mui/icons-material/SimCardAlert";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ActionButtons } from "../components/ActionButtons";
import {
  UploadedFileInfo,
  getFileName,
  getFileSize,
  runAllValidation,
  validateStoreAttributeHeaders,
} from "../utils/PreviewValidationService";
import { IAuditLog } from "../models/IAuditLog";
import { addRecordIdAndGetValidatedRecords } from "../utils/DataModulatorService";
import { AlertBox } from "../components/AlertBox";
import { SnackbarPayload } from "../components/QuickSnackBar";
import { IStoreAttributes } from "../models/IStoreAttributes";
import { MRT_RowSelectionState } from "material-react-table";
import {
  downloadTemplate,
  getExportFromExcel,
  getExcelFileHeaders,
  getExcelRecordErrors,
} from "../utils/utility";
import { DeleteButton, DeleteProps } from "../components/DeleteButton";
import { XlsxSchema } from "../models/XlsxSchema";
import PreviewExcelSchema from "../utils/PreviewExcelSchema";
import { fetchTemplateExcelFile } from "../api/StoreLocationMaster";

export type UploadPageProps = {
  isFullscreen: boolean;
  direction: string;
  uploadedFileInfo: UploadedFileInfo;
  setAuditLog: React.Dispatch<React.SetStateAction<IAuditLog>>;
  setUploadedFileInfo: React.Dispatch<React.SetStateAction<UploadedFileInfo>>;
  nextPage: () => void;
  setPreviewData: React.Dispatch<React.SetStateAction<IStoreAttributes[]>>;
  setIncluded: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  setSnackbarPayload: React.Dispatch<React.SetStateAction<SnackbarPayload>>;
  handleQuitDialogOpen: () => void;
};

export const UploadPage: React.FC<UploadPageProps> = ({
  isFullscreen,
  direction,
  uploadedFileInfo,
  setAuditLog,
  setUploadedFileInfo,
  nextPage,
  setPreviewData,
  setIncluded,
  setSnackbarPayload,
  handleQuitDialogOpen,
}) => {
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const isDisabledOrAction =
    !uploadedFileInfo.isFilePresent || !uploadedFileInfo.isValid
      ? undefined
      : nextPage;

  const severity: AlertColor = !uploadedFileInfo.isValid ? "error" : "success";

  const title: string[] = [
    `File: ${uploadedFileInfo.fileName}`,
    `Error Found: ${uploadedFileInfo.error.length}`,
  ];
  const error: string[] = uploadedFileInfo.error;

  const actionButtonProps = {
    name: "Preview",
    back: undefined,
    action: isDisabledOrAction,
    startIcon: <ArrowForwardIosIcon />,
    cancel: handleQuitDialogOpen,
  };

  useEffect(() => {
    setSnackbarPayload((prev: SnackbarPayload) => ({
      ...prev,
      severity: severity,
    }));
  }, [severity]);

  function setupDataForNextStep(storeAttributesData: any) {
    setPreviewData(storeAttributesData);
    setIncluded(
      storeAttributesData.reduce(
        (include, { importcode }) => ({ ...include, [importcode]: true }),
        {}
      )
    );
  }

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const acceptedFile = event.target.files[0];
    const previewPageSchema: XlsxSchema = PreviewExcelSchema;
    const excelHeaders = await getExcelFileHeaders(acceptedFile);
    if (excelHeaders.errors) {
      const message = getExcelRecordErrors(excelHeaders.errors);
      displayFileError(acceptedFile, [...message]);
      return;
    }
    const headersValidations = validateStoreAttributeHeaders(
      excelHeaders.headers
    );

    if (!headersValidations.isValid) {
      displayFileError(acceptedFile, [headersValidations.message]);
      return;
    }

    getExportFromExcel(acceptedFile, previewPageSchema).then(
      ({ rows, errors }) => {
        if (errors.length === 0) {
          processFileDataForPreview(rows, acceptedFile, errors);
        } else {
          const message = getExcelRecordErrors(errors);
          displayFileError(acceptedFile, [...message]);
        }
      }
    );
  };

  function displayFileError(acceptedFile: File, message: string[]) {
    const filename = getFileName(acceptedFile);
    const filesize = getFileSize(acceptedFile);
    const fileMetaInfo: UploadedFileInfo = {
      isFilePresent: true,
      isValid: false,
      fileName: filename,
      fileSize: filesize,
      error: [...message],
    };
    setUploadedFileInfo(fileMetaInfo);
  }

  /**
   * Run all the validation on the excel file
   * When validations are passed
   * Then setup state variables to hold audit data and preview data
   * @param rows
   * @param acceptedFile
   * @param errors
   */
  function processFileDataForPreview(
    rows: any,
    acceptedFile: File,
    errors: any[]
  ) {
    const fileMetaData: UploadedFileInfo = runAllValidation(
      rows,
      acceptedFile,
      errors
    );
    if (fileMetaData.isValid) {
      setAuditLog((prev) => ({ ...prev, fileName: fileMetaData.fileName }));
      const validateRecords: IStoreAttributes[] =
        addRecordIdAndGetValidatedRecords(rows);
      setupDataForNextStep(validateRecords);
    }
    setUploadedFileInfo(fileMetaData);
  }

  async function downloadTemplateFile() {
    const downloadFilename = "STORE_ATTRIBUTES_EXCEL_TEMPLATE.xlsx";
    const fileBlob = await fetchTemplateExcelFile();
    downloadTemplate(downloadFilename, fileBlob);
  }

  function clearFile() {
    const emptyUploadFile: UploadedFileInfo = {
      isFilePresent: false,
      isValid: true,
      fileName: "",
      fileSize: 0,
      error: [],
    };
    setUploadedFileInfo(emptyUploadFile);
    resetUploadInput();
  }

  function resetUploadInput() {
    if (uploadRef?.current) {
      uploadRef.current.value = "";
    }
  }

  const deleteProps: DeleteProps = { deleteAction: clearFile };
  const alertBoxProps = {
    severity,
    action: <DeleteButton {...deleteProps} />,
    title,
    error,
  };

  return (
    <Box
      className={`upload-container flex-vertical-space-between ${
        isFullscreen ? "container-height-full" : "container-height-mid"
      }  ${direction}`}
    >
      <Typography variant="h5" component="h5" className="margin-bottom-space">
        Upload Store Attributes File Here
      </Typography>
      <Box
        className={`flex-vertical-space-between ${
          isFullscreen ? "container-height-full" : "container-height-mid"
        } `}
      >
        <Stack
          className="download-upload-buttons-container"
          direction="row"
          spacing={2}
        >
          <Button
            color="primary"
            onClick={downloadTemplateFile}
            variant="outlined"
            className={`page-main-action-button ${
              isFullscreen ? "button-height-full" : "button-height-mid"
            }`}
          >
            <>
              <SimCardDownloadIcon className="large-icon" />
              <Typography variant="h6" component="h6">
                Download Template
              </Typography>
              <Typography variant="h6" component="h6" color="grey">
                Click here to download template
              </Typography>
            </>
          </Button>
          <Button
            color={
              !uploadedFileInfo.isFilePresent
                ? "primary"
                : !uploadedFileInfo.isValid
                ? "error"
                : "success"
            }
            component="label"
            variant="outlined"
            className={`page-main-action-button ${
              isFullscreen ? "button-height-full" : "button-height-mid"
            }`}
          >
            <>
              {uploadedFileInfo.isFilePresent ? (
                <>
                  {!uploadedFileInfo.isValid ? (
                    <SimCardAlertIcon className="large-icon" />
                  ) : (
                    <TaskIcon className="large-icon" />
                  )}
                  <Typography variant="h6" component="h6">
                    {`${uploadedFileInfo.fileSize.toFixed(2)} KB`}
                  </Typography>
                  <Typography variant="h6" component="h6">
                    File: {uploadedFileInfo.fileName}
                  </Typography>
                  <Typography variant="h6" component="h6" color="grey">
                    Click here to upload different file
                  </Typography>
                </>
              ) : (
                <>
                  <UploadFileIcon className="large-icon" />
                  <Box className="button-content">
                    <Typography variant="h6" component="h6">
                      Click to upload File
                    </Typography>
                    <Typography variant="h6" component="h6" color="grey">
                      Less than 500 Stores and 2MB
                    </Typography>
                  </Box>
                </>
              )}
              <input
                type="file"
                accept=".xlsx"
                hidden
                ref={uploadRef}
                onChange={handleFileUpload}
              />
            </>
          </Button>
        </Stack>
        <div className="upload-info-bar">
          {uploadedFileInfo.isFilePresent && (
            <Box>
              <AlertBox alertBoxProps={alertBoxProps} />
            </Box>
          )}
        </div>
      </Box>
      <ActionButtons actionButtonProps={actionButtonProps} />
    </Box>
  );
};
