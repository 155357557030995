import { Row } from "read-excel-file/types";
import {
  checkAndAddExcelRecordErrors,
  findMissingKeys,
  getMessageToDisplay,
  validateColumns,
  validateFileRecordCount,
  validateFileSize,
  validateNoDuplicates,
} from "./utility";
import { StoreAttributeHeadersMap } from "../models/IStoreAttributes";
import { Message } from "../constants/ConstantValues";

export type UploadedFileInfo = {
  isFilePresent: boolean;
  isValid: boolean;
  fileName: string;
  fileSize: number;
  error: string[];
};

const uploadedFileInfo: UploadedFileInfo = {
  isFilePresent: false,
  isValid: true,
  fileName: "",
  fileSize: 0,
  error: [],
};

export function getValidationInitObject() {
  return uploadedFileInfo;
}

export function getFileName(acceptedFile: File): string {
  return acceptedFile.name;
}

export function getFileSize(acceptedFile: File): number {
  return acceptedFile.size / 1024;
}

export function runAllValidation(
  records: any,
  acceptedFile: File,
  errorsFromExcel: any
): UploadedFileInfo {
  const uploadedFileInfo: UploadedFileInfo = {
    isFilePresent: false,
    isValid: true,
    fileName: "",
    fileSize: 0,
    error: [],
  };
  if (acceptedFile === null || acceptedFile === undefined)
    return uploadedFileInfo;

  uploadedFileInfo.isFilePresent = true;
  uploadedFileInfo.fileName = acceptedFile.name;
  uploadedFileInfo.fileSize = acceptedFile.size / 1024;
  uploadedFileInfo.isValid = true;
  validateFileSize(acceptedFile, uploadedFileInfo);
  validateFileRecordCount(records, uploadedFileInfo);
  checkAndAddExcelRecordErrors(errorsFromExcel, uploadedFileInfo);
  validateNoDuplicates(records, uploadedFileInfo);

  console.debug(
    "visops-store-attributes-upload: uploadedFileInfo ",
    JSON.stringify(uploadedFileInfo)
  );
  return uploadedFileInfo;
}

export function validateStoreAttributeHeaders(headers: string[]): {
  isValid: boolean;
  message: string;
} {
  const expectedHeaders = Object.values(StoreAttributeHeadersMap);
  let headersValidation: { isValid: boolean; message: string } = {
    isValid: false,
    message: getMessageToDisplay(Message.INVALID_COLUMNS, [
      "0",
      expectedHeaders,
    ]),
  };

  if (!headers) return headersValidation;

  const missingHeaders = expectedHeaders.filter(
    (header) => !headers.includes(header)
  );

  const isValid = missingHeaders.length === 0;
  const message = isValid
    ? ""
    : getMessageToDisplay(Message.MISSING_COLUMNS, [missingHeaders]);
  headersValidation = { isValid, message };

  return headersValidation;
}
