export const DELIMITER = ",";
export const EMPTY = "-Empty-";
export const FILE_SIZE_LIMIT: number = 2048;
export const RECORD_COUNT_LIMIT: number = 500;

export const Message = {
  FILE_TOO_BIG: "File size too big:{0}MB",
  NO_MESSAGE: "",
  MISSING_COLUMNS: "Missing column: [{0}] or validation error found",
  INVALID_COLUMNS:
    "Invalid Excel Records:{0} Ensure data in columns[{1}] are present",
  EMPTY_RECORDS: "No records found!",
  TOO_MANY_RECORDS: "Too many records: {0}",
  EXCEL_ROW_ERROR: "Column [{0}] cell value [{1}] at line number [{2}]",
  DUPLICATE_STOREID_IN_EXCEL:
    "Duplicate records for Store ID [{0}] found [{1}] times",
  FILE_FORMAT_ISSUE: "Please verify the file format and try again, {0}",
};
