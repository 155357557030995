import { Box, Card, Stack, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { ActionButtons } from "../components/ActionButtons";
import { IFinalSubmitDto } from "../models/IFinalSubmitDto";
import { ValidTable } from "../mrtTable/ValidTable";
import { MaterialReactTable } from "material-react-table";
import { InvalidTable } from "../mrtTable/InvalidTable";
import { sendSubmitRequest } from "../api/backendApis";
import { SnackbarPayload } from "../components/QuickSnackBar";
import { ISummary } from "../models/ISummary";
import "./ValidatedPage.css";

type ValidatedPageProps = {
  isFullscreen: boolean;
  direction: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  previousPage: () => void;
  nextPage: () => void;
  setSubmitSummary: React.Dispatch<React.SetStateAction<ISummary>>;
  backendValidated: IFinalSubmitDto;
  setSnackbarPayload: React.Dispatch<React.SetStateAction<SnackbarPayload>>;
  handleQuitDialogOpen: () => void;
};

export const ValidatedPage: React.FC<ValidatedPageProps> = ({
  isFullscreen,
  direction,
  isLoading,
  setIsLoading,
  previousPage,
  nextPage,
  setSubmitSummary,
  backendValidated,
  setSnackbarPayload,
  handleQuitDialogOpen,
}) => {
  function saveValidatedDataAndNextPage() {
    setIsLoading(true);
    sendSubmitRequest(backendValidated).then((submitResponse) => {
      if (submitResponse.apiInfo.type === "success") {
        setSubmitSummary(submitResponse.data);
        nextPage();
      }
      setSnackbarPayload({
        showSnackbar: submitResponse.apiInfo.showSnackbar,
        message: submitResponse.apiInfo.message,
        severity: submitResponse.apiInfo.type,
      });
      setIsLoading(false);
    });
  }

  const validTable = ValidTable(
    isFullscreen,
    isLoading,
    backendValidated?.validRecords
  );
  const invalidTable = InvalidTable(
    isFullscreen,
    isLoading,
    backendValidated?.invalidRecords
  );
  const actionButtonProps = {
    name: "Submit",
    back: previousPage,
    action: saveValidatedDataAndNextPage,
    isLoading,
    startIcon: <SaveIcon />,
    cancel: handleQuitDialogOpen,
  };

  return (
    <Box className={`flex-vertical-space-between ${direction}`}>
      {/* <Typography variant="h5" component="h5" className="margin-bottom-space"> Validated Data !!  Please Review And Submit </Typography> */}
      <Box className={`flex-vertical-space-between mrt-table-container`}>
        <Box
          className={`${isFullscreen ? "valid-table-full" : "valid-table-mid"}`}
        >
          <MaterialReactTable table={validTable.table} />
        </Box>
        <br />
        <Box
          className={`${
            isFullscreen ? "invalid-table-full" : "invalid-table-mid"
          }`}
        >
          <MaterialReactTable table={invalidTable.table} />
        </Box>
        <Typography variant="body1" component="span">
          Note - All error and invalid data will not be saved. Only valid data
          will be saved.
        </Typography>
      </Box>
      <ActionButtons actionButtonProps={actionButtonProps} />
    </Box>
  );
};
