// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-upload-buttons-container {
  width: 100%;
  justify-content: space-evenly;
}

.page-main-action-button {
  display: flex;
  flex-direction: column;
  width: 50%;
  word-break: break-word;
  overflow: hidden;
  min-height: 120px;
}

.button-height-mid {
  height: calc(100vh - 610px);
}

.button-height-full {
  height: calc(100vh - 500px);
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-info-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 120px;
}

.upload-alert-container {
  overflow: hidden;
  position: relative;
}

.upload-alert-errors {
  max-height: 48px;
  width: calc(200vh - 370px);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UploadPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".download-upload-buttons-container {\n  width: 100%;\n  justify-content: space-evenly;\n}\n\n.page-main-action-button {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  word-break: break-word;\n  overflow: hidden;\n  min-height: 120px;\n}\n\n.button-height-mid {\n  height: calc(100vh - 610px);\n}\n\n.button-height-full {\n  height: calc(100vh - 500px);\n}\n\n.button-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.upload-info-bar {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-height: 120px;\n}\n\n.upload-alert-container {\n  overflow: hidden;\n  position: relative;\n}\n\n.upload-alert-errors {\n  max-height: 48px;\n  width: calc(200vh - 370px);\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
