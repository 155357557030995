export interface IStoreAttributes {
  importcode: string;
  seiId: string;
  marketMoveTag: string;
  strategyTestTag: string;
  ownSiteTag: string;
  recordId?: number;
  status?: string;
  errors?: string[];
}

export const StoreAttributeHeadersMap = {
  importcode: "Store ID",
  seiId: "SEI ID",
  marketMoveTag: "Market Move Tag",
  strategyTestTag: "Strategy Test Tag",
  ownSiteTag: "Own Site Tag",
};
