import { XlsxSchema } from "../models/XlsxSchema";

const PreviewExcelSchema: XlsxSchema = {
  "Store ID": {
    prop: "importcode",
    type: String,
    required: true,
  },
  "Market Move Tag": {
    prop: "marketMoveTag",
    type: String,
    required: false,
  },
  "Strategy Test Tag": {
    prop: "strategyTestTag",
    type: String,
    required: false,
  },
  "Own Site Tag": {
    prop: "ownSiteTag",
    type: String,
    required: false,
  },
  "SEI ID": {
    prop: "seiId",
    type: String,
    required: false,
  },
  //   "Validation Status": {
  //     required: false,
  //     type: String,
  //     prop: "validStatus",
  //     parse(value) {
  //       return validateFormula(value);
  //     },
  //   },
};

// function validateFormula(value: any) {
//   console.debug("visops-store-attributes-upload: validateFormula", value);
//   if (typeof value === "string") {
//     const formulaType = value.match(/^([A-Z]+)\(/);
//     console.debug(
//       "Expecting validation formula, but found a regular value",
//       formulaType
//     );

//     if (formulaType && formulaType[1] === "S") {
//       return value;
//     } else {
//       throw new Error(
//         `Invalid formula. Expected "If", but got "${
//           formulaType ? formulaType[1] : "unknown"
//         }"`
//       );
//     }
//   } else {
//     throw new Error("Expecting validation formula, but found a regular value");
//   }
// }

export default PreviewExcelSchema;
