import { Typography } from "@mui/material";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { defaultTableTheme } from "../theme";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IStoreAttributes } from "../models/IStoreAttributes";
import { validResponseColumnDef } from "./StoreAttributesColumnsDef";

export const ValidTable = (
  isFullscreen: boolean,
  isLoading: boolean,
  data: IStoreAttributes[]
) => {
  const muiTableContainerCSSSettings = ({ table }) => ({
    sx: {
      width: "100%",
      height: isFullscreen
        ? "calc(60dvh - 270px) !important"
        : "calc(60dvh - 330px)",
    },
  });

  function handleToggle(selectedRow: any): void {
    console.debug("visops-store-attributes-upload: ValidTable", selectedRow);
  }

  const table = useMaterialReactTable<IStoreAttributes>({
    columns: validResponseColumnDef,
    data: data || [],
    enableBottomToolbar: false,
    enableColumnPinning: true,
    enableRowNumbers: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: "none",
    enableDensityToggle: false,
    enablePagination: false,
    enableRowSelection: false,
    // state: { isLoading: isLoading },
    enableMultiSort: true,
    enableRowVirtualization: true,
    enableGlobalFilter: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableCellActions: false,
    enableHiding: true,
    layoutMode: "grid",
    renderTopToolbarCustomActions: () => (
      <Typography
        color="primary"
        variant="h6"
        component="h6"
        sx={{ marginLeft: "2px" }}
      >
        {" "}
        {`Valid: ${table.getRowModel().rows.length}`}{" "}
      </Typography>
    ),
    getRowId: (row) => row.importcode,
    initialState: {
      density: "compact",
      showColumnFilters: false,
      sorting: [{ id: "recordId", desc: false }],
      columnVisibility: { recordId: false },
    },
    muiSelectAllCheckboxProps: {
      icon: <RadioButtonUncheckedIcon />,
      checkedIcon: <CheckCircleOutlineIcon />,
    },
    muiSelectCheckboxProps: {
      icon: <RadioButtonUncheckedIcon />,
      checkedIcon: <CheckCircleOutlineIcon />,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleToggle(row.original),
      sx: { cursor: "pointer", height: 18, padding: -0.5 },
    }),
    muiTableBodyProps: { sx: { height: "100%" } },
    muiTableContainerProps: muiTableContainerCSSSettings,
    ...defaultTableTheme,
  });
  const selectecRows = table.getRowModel();

  return { table, selectecRows };
};
